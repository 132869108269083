@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 72px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 34px 34px 34px 10px;
  position: relative;

  @media (--viewportMedium) {
    flex-direction: row;
    width: 100%;
    max-width: 1140px;
    height: 209px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 96px;
  }
}

.statCell {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 0;
  text-align: center;
  padding-top: 45px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 129%;
  color: #000000;

  @media (--viewportMedium) {
    width: 20%;
    padding-top: 60px;
  }
}

.statFigure {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 30px;
  background: linear-gradient(312.93deg, #0AB4F3 0.99%, #05D6D8 68.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 10px;
}

.statDelimiter {
  width: 71px;
  height: 3px;
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  background-color: black;

  @media (--viewportMedium) {
    width: 3px;
    height: 71px;
    margin-top: 70px;
    margin-left: 0;
    margin-right: 0;
  }
}

.statCite {
  display: flex;
  flex-direction: column;
  flex-basis: 200px;
  justify-content: center;
  width: calc( 100% + 2px );
  height: 237px;
  margin: 40px -1px -1px -1px;
  flex-grow: 1;
  text-align: left;
  background: linear-gradient(312.93deg, #0AB4F3 0.99%, #05D6D8 68.73%);
  border-radius: 40px 40px 0px 0px;
  padding: 20px;

  @media (--viewportMedium) {
    width: calc( 40% - 6px );
    height: 233px;
    background: linear-gradient(312.93deg, #0AB4F3 0.99%, #05D6D8 68.73%);
    border-radius: 40px 40px 10px 0px;
    margin-top: 0;
    position: absolute;
    right: -1px;
    bottom: -1px;
    padding: 30px;
  }
}

.citeText {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;

  @media (--viewportMedium) {
    line-height: 28px;
  }
}

.citeAuthor {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #FFFFFF;
}

.citeDivider {
  width: 222px;
  height: 2px;
  margin: 10px 0 10px 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);

  @media (--viewportMedium) {
    width: 284px;
    margin: 20px 0 20px 0;
  }
}


.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}
