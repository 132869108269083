@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  background-color: rgba(255, 255, 255, 0);
  margin: 0 24px 0 24px;
}

.titleArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.title {
  @apply --marketplaceH1FontStyles;
  background: linear-gradient(to right, #FA5853, #F4669B, #FFC444);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  font-weight: bold;

  margin: 0 auto;

  @media (--viewportMedium) {
    font-size: 45px;
  }
}

.subtitle {
  color: #303030;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  width: 100%;
  max-width: 918px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.faqTitle {
  /* Section Title */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  /* Text */
  color: #2F2F2F;
}

.steps {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;
  margin-left: 24px;
  margin-right: 24px;
  max-width: 1366px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
    margin-left: 36;
    margin-right: 36px;
  }

  @media (min-width: 1366px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.step {
  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepPictureWrapper {
  composes: step;
  text-align: center;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.stepPictureRightWrapper {
  composes: step;

  @media (--viewportMedium) {
    width: 50%;
    text-align: right;
  }
}

.stepQuestionWrapper {
  composes: step;
  text-align: left;

  @media (--viewportMedium) {
    width: 30%;
  }
}

.stepExplanationWrapper {
  composes: step;

  @media (--viewportMedium) {
    width: 100%;
    margin-top: 50px;
  }
}

.stepActiveExplanationWrapper {
  composes: stepExplanationWrapper;
  box-shadow: 0 0 66px 0 rgba(229, 238, 253, 0.7);
}

.glowWrapper {
  padding: 10px;
  margin-bottom: 20px;
}

.activeGlowWrapper {
  composes: glowWrapper;
  box-shadow: 0 0 66px 0 rgba(229, 238, 253, 0.7);
}

.stepQuestions {
  margin-top: 18px;
  margin-bottom: 18px;
  width: 100%;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.activeQuestionBlock {
  color: #061F29;
  height: 33px;
  border-left: 5px solid #061F29;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding-left: 15px;
  padding-top: 5px;

  @media (--viewportMedium) {
    height: 63px;
    font-size: 18px;
    letter-spacing: 0.2px;
    padding-top: 20px;
  }
}

.questionBlock {
  color: #868686;
  height: 33px;
  border-left: 1px solid #868686;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 19px;
  padding-top: 5px;

  &:hover {
    cursor: pointer;
  }

  @media (--viewportMedium) {
    height: 63px;
    font-size: 18px;
    letter-spacing: 0.2px;
    padding-top: 20px;
  }
}

.explanationPicture {
  width: 100%;
  max-width: 420px;
  height: auto;
}

.stepImage {
  width: 80px;
  height: 80px;
  text-align: center;
  padding-top: 20px;
  background-color: #FFE6D0;
  border-radius: 40px;
  box-shadow: 0px 4px 66px rgba(229, 238, 253, 0.7);

  @media (--viewportMedium) {
    margin-top: 80px;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 32px;
  line-height: 105%;
  color: #061F29;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
    font-weight: 700;
    font-size: 56px;
    line-height: 105%;
    color: #061F29;
  }
}

.activeStepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 21px;
  font-weight: bold;
  color: #061F29;
  border-bottom: 6px solid #061F29;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.explanationBlocks {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    flex-direction: row;
    max-width: 460px;
    margin-bottom: 40px;
  }
}

.explanationBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.explanationItemBlock {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 780px;
}

.explanationStepBlocks {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  align-items: center;
}

.stepSubtitle {
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #868686;
  width: 50%;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  @media (--viewportMedium) {
    margin-top: 11px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    display: flex;
    letter-spacing: 0.2px;
    color: #868686;
  }
}

.rightStepSubtitle {
  composes: stepSubtitle;
  margin-left: 30px;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    margin-left: 40px;
  }
}

.activeStepSubtitle {
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #061F29;
  border-bottom: 6px solid #061F29;

  @media (--viewportMedium) {
    margin-top: 11px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
    color: #061F29;
  }
}

.rightActiveStepSubtitle {
  composes: activeStepSubtitle;
  margin-left: 30px;

  @media (--viewportMedium) {
    margin-left: 40px;
  }
}

.stepItemRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #EBEBEB;
  border-radius: 16px;
  padding: 15px 20px;
  margin: 10px 0;

  @media (--viewportMedium) {
    border: 1.5px solid #EBEBEB;
    border-radius: 24px;
    padding: 20px 30px;
  }
}

.stepItemFigure {
  background-color: #EFEFEF;
  border-radius: 24px 24px 0 24px;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #BABABA;
  width: 48px;
  height: 48px;
  text-align: center;
  padding-top: 8px;
  margin-right: 25px;

  @media (--viewportMedium) {
    margin-right: 35px;
  }
}

.stepItemActiveFigure {
  background-color: white;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: white;
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  text-align: center;
  padding-top: 8px;
  margin-right: 25px;

  &:hover {
    cursor: pointer;
  }

  @media (--viewportMedium) {
    width: 48px;
    min-width: 48px;
    height: 48px;
    margin-right: 15px;
  }
}

.stepItemText {
  font-weight: 500;
  font-size: 18px;
  line-height: 102%;
  letter-spacing: 0.2px;
  padding-top: 8px;
  color: #EFEFEF;

  @media (--viewportMedium) {
    line-height: 22px;
    font-size: 20px;
    font-weight: 700;
  }
}

.stepItemActiveTitle {
  font-family: 'Rany', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  text-transform: capitalize;

  padding-top: 8px;
  color: #061F29;

  @media (--viewportMedium) {
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.2px;
  }
}

.stepItemActiveText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  margin-top: 10px;
  color: #9C9C9C;

  @media (--viewportMedium) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #9C9C9C;
  }
}

.stepItemPipeRow {
  display: flex;
  flex-direction: column;
  width: 48px;
  height: 100%;
  margin-right: 15px;
}

.stepItemPipe {
  margin-left: 23px;
  width: 48px;
  height: 100%;
}

.stepItemTextRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.stepText {
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 17px;
  font-weight: 300;
  color: #9F9F9F;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.signupButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  margin-bottom: 20px;
  width: 100%;
  min-height: 51px;
  height: 51px;
  border-radius: 16px;
  border: 2px solid var(--marketplaceColor);
  padding-top: 13px;
  padding-bottom: 13px;
  color: var(--marketplaceColor);
  background-color: white;

  &:hover {
    color: var(--marketplaceColorDark);
    background-color: white;
  }

  @media (--viewportMedium) {
    display: block;
    width: 200px;
    height: 51px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}