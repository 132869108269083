@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 340px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 72px;
  position: relative;
  background: url(./images/background.jpg), linear-gradient(312.93deg, #0AB4F3 0.99%, #05D6D8 68.73%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (--viewportMedium) {
    flex-direction: row;
    width: 100%;
    height: 530px;
    margin-top: 96px;
  }
}

.mainBlockWrapper {
  width: 100%;
  text-align: center;
}

.mainBlock {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 0px 11px 39px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  position: relative;
  margin-left: 24px;
  margin-right: 24px;
  padding: 37px 24px;
  top: -120px;

  @media (--viewportMedium) {
    margin-left: auto;
    margin-right: auto;
    padding: 46px 66px;
    max-width: 1140px;
    top: -315px;
  }
}

.contentBlock {
  display: flex;
  flex-direction: column;
  background-image: url(./images/mobile-background.jpg);
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-bottom: 42px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    background-image: url(./images/desktop-background.jpg);
    padding-bottom: 47px;
  }
}

.contentText {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    flex-basis: 300px;
    flex-grow: 1;
  }
}

.contentPicture {
  display: block;
  flex-direction: column;
  width: 100%;
  margin-top: 56px;

  @media (--viewportMedium) {
    flex-basis: 403px;
    flex-grow: 0;
    margin-top: 0;
  }
}

.explanationImage {
  width: 100%;
  height: auto;
}

.footerBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.footerImageWrapper {
  position: relative;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.footerImageSection {
  width: 19px;
  height: 24px;
  position: absolute;
  top: 0;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    width: 24px;
    height: 32px;
  }
}

.footerImageSection1 {
  composes: footerImageSection;

  left: 0;
}

.footerImageSection21 {
  composes: footerImageSection;

  left: 19px;

  @media (--viewportMedium) {
    left: 24px;
  }
}

.footerImageSection22 {
  composes: footerImageSection;

  left: 42px;

  @media (--viewportMedium) {
    left: 54px;
  }
}

.footerImageSection3 {
  composes: footerImageSection;

  left: 62px;

  @media (--viewportMedium) {
    left: 78px;
  }
}

.title {
  composes: h1 from global;
  margin: 33px auto 0 auto;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;

  @media (--viewportMedium) {
    max-width: 830px;
    margin: 61px auto 0 auto;
    font-weight: 700;
    font-size: 40px;
    color: #FFFFFF;
  }
}

.decideAndApplyTitle {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #000000;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 120%;
    margin-top: 85px;
  }
}

.decideAndApplyTitle2 {
  composes: decideAndApplyTitle;

  @media (--viewportMedium) {
    margin-top: 10px;
  }
}

.applySubtitle {
  background: linear-gradient(312.93deg, #0AB4F3 0.99%, #05D6D8 68.73%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.description {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  opacity: 0.6;
  margin-top: 15px;

  @media (--viewportLarge) {
    padding-right: 45px;
  }

  @media (--viewportXLarge) {
    padding-right: 90px;
  }
}

.actionButton1 {
  background: linear-gradient(312.93deg, #0AB4F3 0.99%, #05D6D8 68.73%);
  color: white;
  border-radius: 100px;
  width: 169px;
  height: 42px;
  text-align: center;
  margin-top: 30px;
  padding-top: 10px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  @media (--viewportMedium) {
    font-weight: 600;
  }
}

.actionFooterLine {
  width: 100%;
  text-align: center;
}

.actionButton2 {
  background: linear-gradient(312.93deg, #0AB4F3 0.99%, #05D6D8 68.73%);
  border-radius: 100px;
  color: white;
  width: 220px;
  height: 52px;
  margin-top: 43px;
  padding: 15px 40px;
  position: relative;
  top: -50px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  @media (--viewportMedium) {
    font-weight: 700;
    margin-top: 50px;
    top: -245px;
  }
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}

.arrowImage {
  margin-left: 10px;

  @media (--viewportMedium) {
    margin-left: 15px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}
