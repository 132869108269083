@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  max-width: 1128px;
  margin: 0 auto 80px;
  padding: 0 24px;

  @media (--viewport1280) {
    padding: 0;
  }
}

.title {
  margin: 0;
  /* Section Title */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;
  /* Text */
  color: #2F2F2F;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.subtitle {
  max-width: 780px;

  /* Section Subtitle */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  text-align: center;

  /* Text */
  color: #2F2F2F;


}

.buttonContainer {
  display: flex;
  gap: 16px;
  overflow-x: scroll;
  width: 100%;

  @media (--viewportLarge) {
      justify-content: center;
  }
}

.buttonContainer::-webkit-scrollbar {
  display: none;
}

.selectionButton {
  /* Tags */


  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 10px 16px;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;

  /* White */
  background: #F4FCFF;
  border-radius: 4px;

  cursor: pointer;
}

.selectedButton {
  /* Tags */


  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 10px 16px;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;

  /* White */
  border: 2px solid transparent;
  background: linear-gradient(to right, white, white), linear-gradient(152deg, rgba(5, 214, 216, 1) 0%, rgba(0, 170, 237, 1) 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border-radius: 4px;
  cursor: pointer;


}

.buttonText {
  /* Section Subtitle */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  text-align: center;
  white-space: nowrap;
  /* Text */
  color: #2F2F2F;
  margin: 0;
}

.recommendationsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.recomTop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.recomTitle {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Text */
  color: #2F2F2F;
}

.seeAll {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* Hyperlinks */
  color: #007EB0;
}

.buttonIcon {
  width: 26px;
  height: 26px;
}

.recomPapersContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
  position: relative;
  width: 100%;
}

.recomPapers {
  width: 100%;
  
  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }

  @media (--viewportLarge) {
    width: calc(25% - 24px);
  }
}

.overlay {
  pointer-events: none;
  height: 342px;
  position: absolute;
  right: calc(570px - 50vw);
  width: 150px;
  z-index: 3;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  bottom: 0px;
  display: none;

  @media (min-width:1440px) {
      right:0px;
  }

  @media (min-width:1140px) {
      display: unset;
  }
}

.recomPapersContainer::-webkit-scrollbar {
  display: none;
}