@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  color: var(--matterColor);
  font-family: 'Inter';

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 8px;
  overflow: hidden;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 8px;
}

.info {
  /* Layout */
  padding: 8px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  margin-right: 18px;

  font-size: 16px;
  line-height: 24px;
}

.priceValue {
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin: 0 6px 0 0;
}

.perUnit {
  /* Font */
  font-weight: var(--fontWeightRegular);
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.ratingInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  line-height: 24px;
  min-height: 24px;

  font-size: 18px;
  line-height: 24px;

  & b {
    font-weight: var(--fontWeightSemiBold);
  }
}

.star {
  width: 18px;
  height: 18px;
  fill: #05D6D8;
  transition: var(--transitionStyleButton);
  margin-right: 5px;
}

.title {
  /* Font */
  composes: h3 from global;
  color: #2F2F2F;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  font-size: 20px;
  line-height: 24px;
  font-weight: var(--fontWeightBold);
}

.authorInfo {
  /* Font */
  color: #2F2F2F;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  font-size: 16px;
  line-height: 24px;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
