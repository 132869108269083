@import '../../styles/customMediaQueries.css';


.root {
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    padding: 80px 24px;
    background-color: #F4FCFF;

    @media (--viewportXLarge) {
        padding: 80px 0px;
    }
}

.generalWrapper {
    display: flex;
    max-width: 1128px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media (--viewportMedium) {
        gap: 80px;
        flex-direction: row;
    }

    @media (min-width:1440px) {
        max-width: 1400px;
    }
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    @media (--viewportMedium) {
        align-items: flex-start;
    }
}

.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #2F2F2F;
    margin: 0px;
    text-align: center;

    @media (--viewportMedium) {
        text-align: left;
    }
}

.content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4A4A4A;
    margin: 0px;
    text-align: center;

    @media (--viewportMedium) {
        text-align: left;
    }
}

.sectionImage {
    width: 100%
}