@import '../../styles/customMediaQueries.css';
.root {
    display: flex;
    flex-direction: column;
    gap:4px;
    width: 100%;
    min-width: 267px;
    cursor: pointer;
}

.rankingContainer {
    margin-top: 4px;
    display: flex;
    width: 100%;
    gap:4px;
    align-items: center;
}

.rankingContainer > * {
    margin:0
}

.rankingStar {
    width: 18px;
    height: 18px;
}

.mainImage {
    width: 100%;
    border-radius: 4px;
}

.ranking {
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 133% */

/* Text */
color: #4A4A4A;

}

.rankingAmount {
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 133% */

/* Text */
color: #4A4A4A;
}

.title {
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
margin: 0;
/* Text */
color: #2F2F2F;
}

.mentorName {
/* Card hyperlink */
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */
margin: 0;
/* Text */
color: #2F2F2F;
}

.priceStatement {
margin: 0;
/* Card hyperlink */
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

color: #4A4A4A;

}

.priceStatement > b {
    font-weight: 600;
}