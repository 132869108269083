@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  display: grid;
  gap: 16px;
  grid-template-columns: 100%;
  @media (--viewportMedium) {
    grid-template-columns: 50% 50%;
  }
}

.reviewItem {
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}



.ratingContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.review {
  display: flex;
  flex-direction: column;
  gap: 16px
}

.avatar {
  width: 48px;
  height: 48px;
}

.reviewContent {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #4A4A4A;

  margin: 0
}

.reviewInfo {
  composes: h5 from global;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.authorName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  color: #4A4A4A;

}

.reviewHeader {
  display: flex;
  align-items: center;
  gap: 16px;
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.date {
  /* 12pt text */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  /* Text */
  color: #4A4A4A;
  margin: 0;
  padding-left: 8px;

  border-left: solid 1px #D8DCE6;
}

.mobileReviewRating {
  display: block;
  margin-bottom: 2px;
  line-height: 0;
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}