.linkButton {
    background-color: #05D6D8;
    padding: 8px 24px;
    border-radius: 100px;
    cursor: pointer;
    width: max-content;
    /* + Add your stuff */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    /* Text */
    color: #2F2F2F;

}