@import '../../styles/customMediaQueries.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}

.fullWidthTable {
  display:  table;
  width: 100%;
  background-color:white;
  border: 1px solid #888888;
  border-spacing:0;/*cellspacing:poor IE support for  this*/
/* border-collapse:separate;*/
}

.tableRow {
  display: table-row;
}

.tableCell {
  display: table-cell;
  border: 1px solid #888888;
  padding : 5px;/*cellspacing:poor IE support for  this*/
}

.referralsLink {
  color: #4A4A4A;
  text-decoration: underline;
}
