.inputBox {
  position: absolute;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  max-width: 100%;
}

.faq {
  width: 100%;
}

.label {
  /* Card title */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  /* Text */
  color: #4A4A4A;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.elemContainer {
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
}

.elemContainer:not(:last-child) {
  border-bottom: 1px solid #D8DCE6;
}

.label::before {
  content: '\002B';
  margin-right: 13.5px;
  margin-bottom: 5px;
  transform: scale(1.8);
  text-align: center;
  color: #05D6D8;
  transition: all 0.35s;
}

.content {
  max-height: 0;
  margin-left: 10px;
  padding: 0 16px;
  color: #2c3e50;
  transition: all 0.35s;
  display: none;
}

.inputBox:checked+.label::before {
  content: '\2013';
  transform: scale(1.5);
}

.inputBox:checked~.content {
  max-height: 100vh;
  padding: 1em;
  display: block;
  transition: all 0.35s;
}