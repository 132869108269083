@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.category {
  composes: formMargins;
}

.cancellationPolicyTitle {
  composes: formMargins;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #B2B2B2;
  margin-top: 15px;
  margin-bottom: 15px;
}

.cancellationPolicyText {
  composes: formMargins;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #4A4A4A;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
