@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 72px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
  
  @media (--viewportMedium) {
    max-width: 1274px;
    margin-top: 96px;
  }
}

.titleContainer {
  gap:16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title {
  composes: h1 from global;
  margin: 0;
  text-align: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #2F2F2F;

}

.subtitle {
  max-width: 780px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #2F2F2F;
}

.blogPosts {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding-top: 16px;
}

.blogPosts::-webkit-scrollbar {
  display: none;
}

.blogPost {
  width: 100%;
  text-align: left;
  border-radius: 8px;

  @media (--viewportMedium) {
    max-width: calc(50% - 24px);
  }

  @media (--viewport1280) {
    max-width: calc(25% - 24px);
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  height: 186px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(2 / 3 * 100%);
  /* 13:11 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 13:10 Aspect Ratio */
  }
}

.blogPostImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.linkText {
  margin-top: 20px;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.blogPostTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;

  /* Text */
  color: #2F2F2F;
}

.blogPostDescription {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: black;
  line-height: 22px;
  opacity: 0.7;
  margin: 0 20px 20px 20px;
}

.blogPostLinkLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 20px 20px 20px;
}

.blogPostLink {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #0AB4F3;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.recomTop{
  width: 100%;
  margin-top: 40px;
}

.recomTop {
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.recomTitle {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Text */
  color: #2F2F2F;
}

.seeAll {
  font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* Hyperlinks */
    color: #007EB0;
}