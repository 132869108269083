@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.buttonIcon {
  width: 26px;
  height: 26px;
}

.selectionButton {
  /* Tags */
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 13px 16px;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;

  /* White */
  background: #F4FCFF;
  border-radius: 4px;

  cursor: pointer;
  width: min-content;
}

.buttonText {
  /* Section Subtitle */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  text-align: center;
  white-space: nowrap;
  /* Text */
  color: #2F2F2F;
  margin: 0;
}

.categorySubTitle {
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.categorySubTitle1 {
  composes: categorySubTitle;
  width: calc(100% - 48px);
  max-width: 1400px;
  margin-left: 24px;
  margin-right: 24px;
  text-align: left;

  @media (--viewportLarge) {
    margin-left: auto;
    margin-right: auto;
  }
}

.categorySubDescription {
  width: calc(100% - 48px);
  max-width: 1000px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 24px;
  margin-right: 24px;

  @media (--viewportLarge) {
    margin-left: auto;
    margin-right: auto;
  }
}

.categorySubDescription1 {
  composes: categorySubDescription;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.categoryExplore {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
  margin-left: 24px;

  @media (--viewportMedium) {
    margin-left: 36px;
  }

  @media (--viewportLarge) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
  }
}

.categoryDiscoverTitle {
  margin-top: 80px;
  margin-bottom: 40px;
  /* Section Title */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  /* Text */
  color: #2F2F2F;
}

.listingCards {
  padding: 0 24px 96px 24px;
  display: grid;
  justify-content: center;
  grid-template-columns: 100%;
  gap: 16px;
  width: 100%;

  @media (--viewportMedium) {
    grid-template-columns: repeat(auto-fill, 276px);
    gap: 16px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(auto-fill, minmax(276px, 1fr));
  }

  @media (--viewportLarge) {
    padding: 0 36px 96px 36px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
  }
}

.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(25% - 24px);
    margin-right: 24px;
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(25% - 24px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(3n) {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(4n) {
  @media (--viewportLarge) {
    margin-right: 0;
  }

  @media (--viewportXLarge) {
    margin-right: 24px;
  }
}

.listingCard:nth-of-type(4n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.locations {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 80px;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.location {
  width: 100%;
  width: min-content;
  text-align: center;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

}


/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(6 / 7 * 100%);
    /* 3:2 Aspect Ratio */
  }

  @media (--viewportLarge) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }

  @media (min-width: 1140px) {
    padding-bottom: calc(4 / 7 * 100%);
    /* 3:2 Aspect Ratio */
  }

  @media (min-width: 1260px) {
    padding-bottom: calc(1 / 2 * 100%);
    /* 3:2 Aspect Ratio */
  }


  @media (min-width: 1400px) {
    padding-bottom: calc(3 / 7 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.footerImageWrapper {
  position: relative;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.footerAspectWrapper {
  padding-bottom: calc(100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(100%);
    /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.locationDescription {
  width: 100%;
  margin-top: 25px;
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 40px;
    width: 40%;
  }
}

.actionButton1 {
  background: linear-gradient(312.93deg, #0AB4F3 0.99%, #05D6D8 68.73%);
  color: white;
  border-radius: 100px;
  width: 209px;
  height: 42px;
  text-align: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  @media (--viewportMedium) {
    font-weight: 600;
    margin-top: 50px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.scheduleSection {
  composes: sectionContent;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    max-width: 1204px;
    padding: 0;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 0 auto;
  }

  @media (--viewportXLarge) {
    max-width: 1204px;
    padding: 0;
  }
}


.scheduleRoot {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 16px;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    width: 100%;
    margin-top: 24px;
    padding: 0 32px 0 32px;
  }
}