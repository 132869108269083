@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 72px;

  @media (--viewportMedium) {
    width: 100%;
    max-width: 1274px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 96px;
  }
}

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
  text-align: center;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;
  color: #000000;

  @media (--viewportMedium) {
    font-weight: 700;
    font-size: 40px;
  }
}

.titleMentors {
  background: linear-gradient(312.93deg, #0AB4F3 0.99%, #05D6D8 68.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.profiles {
  display: flex;
  flex-direction: column;
  margin-top: 28px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 52px;
  }
}

.profile {
  width: 100%;
  margin-top: 25px;
  text-align: center;

  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  padding: 14px;

  &:hover {
    text-decoration: none;
    background: linear-gradient(312.93deg, #0AB4F3 0.99%, #05D6D8 68.73%);
   }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 24px;
  }
}

.profile:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.arrowDesktopImage {
  display: none;
  margin: auto 0;

  @media (--viewportMedium) {
    display: block;
  }
}

.inactiveArrowDesktopImage {
  composes: arrowDesktopImage;
}

.inactiveArrowDesktopLeftImage {
  composes: inactiveArrowDesktopImage;
  margin-right: 20px;
}

.inactiveArrowDesktopRightImage {
  composes: inactiveArrowDesktopImage;
  margin-left: 20px;
}

.activeArrowDesktopImage {
  composes: arrowDesktopImage;

  &:hover {
    cursor: pointer;
  }
}

.activeArrowDesktopLeftImage {
  composes: activeArrowDesktopImage;
  margin-right: 20px;
}

.activeArrowDesktopRightImage {
  composes: activeArrowDesktopImage;
  margin-left: 20px;
}

.arrowMobileRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.arrowMobileImage {
  display: block;
  margin: 0 auto;

  @media (--viewportMedium) {
    display: none;
  }
}

.inactiveArrowMobileImage {
  composes: arrowMobileImage;
}

.inactiveArrowMobileLeftImage {
  composes: inactiveArrowMobileImage;
  margin-right: 20px;
}

.inactiveArrowMobileRightImage {
  composes: inactiveArrowMobileImage;
  margin-left: 0;
}

.activeArrowMobileImage {
  composes: arrowMobileImage;

  &:hover {
    cursor: pointer;
  }
}

.activeArrowMobileLeftImage {
  composes: activeArrowMobileImage;
  margin-right: 20px;
}

.activeArrowMobileRightImage {
  composes: activeArrowMobileImage;
  margin-left: 0;
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(11 / 13 * 100%); /* 13:11 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(10 / 13 * 100%); /* 13:10 Aspect Ratio */
  }
}

.profileImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  object-fit: cover;
}

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.profileName {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: black;

  &:hover {
    color: white;
  }

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 27px;
  }
}
