@import '../../styles/customMediaQueries.css';

.numberInputWrapper {
    display: flex;
    align-items: center;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    height: min-content;
    overflow: hidden;
    margin:0px 24px;

    @media --viewportMedium {
        margin: 0 0 16px 0;
    }
}

.numberInputWrapper svg {
    fill: none;
    width: 16px;
    height: 10px;
    padding: 1px 2px;
}

.numberInput {
    -webkit-appearance: none;
    border: none;
    width: 100%;
    padding: 8px 16px;
    outline: none;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #4A4A4A;
}



.numberInput::-webkit-inner-spin-button,
.numberInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.buttonsWrapper {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
}

.incrementButton,
.decrementButton {
    cursor: pointer;
    background-color: white;
    border: none;
    stroke: #4A4A4A;
    outline: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inactiveDecrementButton {
    composes: decrementButton;
    cursor: auto;
    stroke: #D8DCE6;
}

.inactiveDecrementButton:hover {
    background-color: transparent;
}

.incrementButton {
    transform: rotate(0deg);
    /* Default orientation */
}

.decrementButton {
    transform: rotate(180deg);
    /* Rotate the button to simulate down arrow */
}

.incrementButton:hover,
.decrementButton:hover {
    background-color: #f0f0f0;
}