@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.defaultBackgroundImage {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(90.01deg, #004561 48.61%, #005B80 74.04%, #004561 99.99%), url(./images/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;

  @media (--viewportLarge) {
    border-radius: 0;
    border: solid 0 var(--matterColorBright);
  }
}

.root {
  composes: defaultBackgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.heroContent {
  margin: 0 24px 0 24px;
  text-align: left;

  @media (--viewportMedium) {
    margin: 0 15vw 0 0;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1140px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1140px) {
    margin: 0 auto;
    max-width: 1400px;
    width: 100%;
    padding: 0;
  }
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  composes: animation;
  margin-left: 0;
  margin-right: auto;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 120%;
  color: #FFFFFF;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 45px;
  }
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.storyMainTitle {
  color: #06D0DD;
}

.heroSubTitle {
  composes: h4 from global;

  margin: 0 auto 16px 0;
  composes: animation;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;

  @media (--viewportMedium) {
    margin: 0 auto 27px 0;
    line-height: 24px;
  }
}

.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.searchWrapper {
  /* Size */
  width: 100%;
  max-width: 700px;
  margin-left: 0;
  margin-right: auto;
  text-align: left;
  height: 50px;
  border-radius: 100px;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
    height: 56px;
  }
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: 50px;
  position: relative;

  @media (--viewportLarge) {
    padding-left: 24px;
    height: 56px;
  }
}

.inDemandWrapper {
  /* Size */
  width: 100%;
  max-width: 700px;
  margin-left: 0;
  margin-right: auto;
  text-align: left;

  /* Layout for child components */
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding-top: 15px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.inDemandLabel {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  padding-top: 4px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  color: #FFFFFF;

  @media (--viewportMedium) {
    width: auto;
    margin-bottom: 0;
    font-size: 18px;
  }
}

.inDemandRow {
  display: flex;
  flex-direction: row;
}

.inDemandTag {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  color: #FFFFFF;
  padding: 6px 12px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid #FFFFFF;
  border-radius: 100px;
  margin-left: 10px;

  @media (--viewportMedium) {
    font-size: 14px;
    border: 1px solid #FFFFFF;
  }
}